import { url } from "../common/const";
import { get, post } from "../common/https";

/**This service for get destination list*/
export const GetSlideList = (params) => {
  const getdata = get(`${url}/api/Slide/List?` + params);
  return getdata;
};

/**This service for Save lide */
export const SaveSlide = (data) =>
  post(`${url}/api/DestSlideination/Save`, data);

export const SaveSlideImage = (data) => {
  post(`${url}/api/Destination/SaveImage`, data);
};
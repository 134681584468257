
import React, {useState, useEffect} from "react";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Tooltip,
  Row,
  Card,CardBody,CardHeader,FormFeedback,Collapse,
  Button
} from "reactstrap";
import ButttonTravelNinjaz from "../../../components/Common/GloablMaster/ButttonTravelNinjaz";

import { setIn, useFormik } from "formik";
import * as Yup from "yup";
//import LoadingButton from "../../common/LoadingButton";
import { getSessionUsingSessionStorage, getSession } from "../../../services/common/session";
import {  SaveSlide } from "../../../services/Home/slideService";
import {errornotify,successNotify, dismissNotify} from "../../../components/Common/notification"

const SlidePopupModal = ({
   slideDetails 
  ,isOpen 
  ,tog_scroll
  ,size
  ,scrollable
  ,readOnly
  ,cancelHandller
 
}) => {
  // Tooltip Open state
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const currencySymbol = localStorage.getItem("currencySymbol");
  const [userName, setUserName] = useState();
    const [userDetails, setUserDetails] = useState(undefined);

   useEffect(() => {
      let promise = getSessionUsingSessionStorage();
      promise
        .then(function (value) {
          return value;
        })
        .then((value) => {
          setUserName(value.userName);
          setUserDetails(value);
        });
    }, []);
  


  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {  
      //operation:   slideDetails?.operation,
      slide_name: slideDetails?.slide_name || "",
      slide_title: slideDetails?.slide_title || "",    
      slide_description: slideDetails?.slide_description || "",    
    },
    validationSchema: Yup.object({       
      slide_name: Yup.string().required("Please enter Slide Name"),      
      slide_title: Yup.string().required("Please enter Slide Title"),      

    }),
    onSubmit: (values) => {
        alert(values);
        SaveHomeSlide(values);      
          
    },
  });

  
    /*This function for save Home Slide data */
    const SaveHomeSlide = async (values) => {
      try {         
                               
             
  
                const newInquiry =  {
                  "slide_name": values?.slide_name,
                  "slide_title": values?.slide_title,
                  "slide_description": values?.slide_description,
                  //"is_active": values?.is_active,
                  "is_active": true,    
                  "row_created_date":  new Date(),
                  "row_created_by": userName,
                  "row_altered_date":  new Date(),
                  "row_altered_by": userName,       
              };
  
  
             const response = await SaveHomeSlide(newInquiry);
  
             //alert('response?.status ' + response?.status);
          
             //alert('response.errors' + JSON.stringify(response.errors));

             //dismissNotify();
             if (response?.status === "SUCCESS") {
                      successNotify(response?.message);

                      //Hide Off Canvas
                      tog_scroll();
              } else 
              {
                  if(response?.errors)                    
                      errornotify(JSON.stringify(response.errors));
                  else
                      errornotify(response?.message);
              }
          // } 
            
          //   else {
          //     const updateInquiry = values;    
              
          //   }

            
  
        
      } catch (error) {
        errornotify(error);
      }
    };     


  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={tog_scroll}
        centered
        size={size}
        scrollable={scrollable}
      >
        <ModalHeader className="bg-light p-3">{`${!readOnly ? "Add" : "Edit"} - Home Slide`}</ModalHeader>
        <ModalBody>
          <form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
              action="#"
              >
            
              <Row>
                      <Col lg={12}>                      
                              
                          <Row>
                              <Col lg={6}>
                                  <div className="mb-3">
                                      <Label  className="form-label" htmlFor="slide_name">Name</Label>
                                  
                                      <Input
                                      type="text"
                                      id="slide_name"
                                      name="slide_name"
                                      className="form-control"                                   
                                      placeholder="Enter Slide Name"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.slide_name || ""}
                                      invalid={
                                      validation.touched.slide_name &&
                                      validation.errors.slide_name
                                          ? true
                                          : false
                                      }
                                  />
                                  {validation.touched.slide_name &&
                                  validation.errors.slide_name ? (
                                      <FormFeedback type="invalid">
                                      {validation.errors.slide_name}
                                      </FormFeedback>
                                  ) : null}                                    

                                  </div>
                              </Col>
                              <Col lg={6}>

                              <div className="mb-3">
                                  <Label htmlFor="Title" className="form-label">
                                      Title
                                  </Label>
                                  <Input
                                      type="text"
                                      id="slide_title"
                                      name="slide_title"
                                      className="form-control"                                   
                                      placeholder="Enter Slide Title"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.slide_title || ""}
                                      invalid={
                                      validation.touched.slide_title &&
                                      validation.errors.slide_title
                                          ? true
                                          : false
                                      }
                                  />
                                  {validation.touched.slide_title &&
                                  validation.errors.slide_title ? (
                                      <FormFeedback type="invalid">
                                      {validation.errors.slide_title}
                                      </FormFeedback>
                                  ) : null}
                                  </div>                                 

                              </Col>
                          </Row>     

                          <Row>
                              <Col lg={12}>
                                  <div className="mb-3">
                                      <Label  className="form-label" htmlFor="slide_description">Description</Label>
                                  
                                      <Input
                                      type="textarea"
                                      id="slide_description"
                                      name="slide_description"
                                      className="form-control"                                    
                                      placeholder="Enter Slide Description"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.slide_description || ""}
                                      invalid={
                                      validation.touched.slide_description &&
                                      validation.errors.slide_description
                                          ? true
                                          : false
                                      }
                                  />
                                  {validation.touched.slide_description &&
                                  validation.errors.slide_description ? (
                                      <FormFeedback type="invalid">
                                      {validation.errors.slide_description}
                                      </FormFeedback>
                                  ) : null}                                    

                                  </div>
                              </Col>                            
                          </Row>                                                                                                    

                          <Row>
                              <Col lg={12}>                             
                                  {/* <CitySiteSeeingImages
                                        cancelHandller={false}
                                        getImageFileNames={getImageFileNames}
                                        handleAcceptedFiles={handleAcceptedFiles}
                                        modalSaveHandller={modalSaveHandller}
                                        onDeleteHandller={onDeleteHandller}
                                        onImagePrimaryHandller={onImagePrimaryHandller}
                                        removeSelectedImages={removeSelectedImages}
                                        selectedFiles={selectedFiles}
                                      /> */}
                              </Col>                            
                          </Row>   

                            <Row>
                              <Col lg={6}>                                                                   

                                  <div className="mb-3">
                                      <Label className="form-label" htmlFor="is_active">Is Active                                                                   
                                      </Label>
                                      <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                                          <Input 
                                              type="checkbox" 
                                              className="form-check-input" 
                                              id="is_fixed_departure"
                                              name="is_fixed_departure"
                                              value={validation?.values?.is_active}  
                                              checked = {validation?.values?.is_active}  
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                          />                 
                                      </div>
                                  </div>

                              </Col>                                                          
                          </Row>        


                  </Col>
                
              </Row>   

              <div className="d-flex align-items-center gap-3 mt-4">
                  <div className="ms-auto nexttab nexttab">
                  
                      <ButttonTravelNinjaz
                        backGroundColor={"primary"}
                        //onCLickHancller={modalSaveHandller}
                        buttonText={"Save"}
                        className="mx-1"
                        type={"submit"}
                      />                

                      <ButttonTravelNinjaz
                        backGroundColor={"danger"}
                        onCLickHancller={cancelHandller}
                        buttonText={"Cancel"}
                        className="mx-1"
                      />
                  </div>
              </div>

    
          </form>
        </ModalBody>
       
          
      </Modal>
    </>
  );
};

export default SlidePopupModal;

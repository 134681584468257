import React, { useEffect, useContext} from "react";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Container, Input, Row } from "reactstrap";
import ColumnVisibleAndHide from "../../../components/Common/GloablMaster/columnvisibleAndHide/ColumnVisibleAndHide";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import ButttonTravelNinjaz from "../../../components/Common/GloablMaster/ButttonTravelNinjaz";
import Export from "../../../components/Common/GloablMaster/export/Export";
import Mastersearch from "../../../components/Common/GloablMaster/Mastersearch";
import { Table, Image } from "antd";
import SlidePopupModal from "../Slide/SlidePopupModal";
import { GetSlideList } from "../../../services/Home/slideService";
import { GetCityList } from "../../../services/GlobalMasters/cityServices";
import {
  errornotify,
  successNotify,
} from "../../../components/Common/notification";
import {
  getCitySiteSeeingList,
  saveCitySiteSeeingList,
  getCityAndSiteseeingList,
  DeleteCitySiteSeeingImageList,
  SaveCitySiteSeeingImageList
} from "../../../services/GlobalMasters/citySiteSeeingService";
import { destinationTypesFilter } from "../../../components/constants/destinationTypes";
import {
  downloadExcel,
  filterUniqueCity,
  filterUniqueDestination,
} from "../../../functions/downloadtoExcel";
import { appName } from "../../../common/applicationName";
import {
  getLocalStorageItem,
  getSessionUsingSessionStorage,
} from "../../../services/common/session";
import { getUserAndCompany } from "../../../services/User/userService";
import CurrencyShow from "../../../common/CurrencyShow";
import { useNavigate } from "react-router-dom";
import { authError , url} from "../../../services/common/const";
import UseAuth from "../../../components/UseAuth";
import { defaultCity } from "../../../services/common/GlobalVariables";

const Slide = () => {
  UseAuth();

  // document.title = "CitySiteSeeing | TravelNinjaz B2B";
  document.title = `${appName}-CitySiteSeeing`;
  const [userName, setUserName] = useState();
  const [userDetails, setUserDetails] = useState(undefined);
  const currencySymbol = localStorage.getItem("currencySymbol");

  const [selectedFiles, setselectedFiles] = useState([]);
  const [getImageFileNames, setGetImageFileNames] = useState([]);
  const [deleteImageList, setDeleteImageList] = useState([]);
  const [isPrimaryImage, setIsPrimaryImage] = useState({
    city_site_seeing_image_id: null,
    is_primary: false,
  });
  const { city_site_seeing_image_id, is_primary } = isPrimaryImage;

  const [slideDetails, setSlideDetails] = useState([]);

  const navigate = useNavigate();
  /** get session details*/


    /**selection state */
    const [modalValue, setModalValue] = useState({
      city_site_seeing_id: 0,
      destinationType: null,
      destination: null,
      // city_id: defaultCity.city_id,
      // city_name: defaultCity.city_name,
      city_id: null,
      city_name: null,
      is_active: true,
      operation: "",
      site: null,
      private_rate: null,
      sic_rate: null,  
    });
    const { city_site_seeing_id, destinationType, destination, city_id, city_name, is_active, site, private_rate,sic_rate} = modalValue;



  useEffect(() => {
    let promise = getSessionUsingSessionStorage();
    promise
      .then(function (value) {
        return value;
      })
      .then((value) => {
        setUserName(value.userName);
        setUserDetails(value);
      });
  }, []);

  const [exportTableData, setExportableData] = useState([]);
  const [exportedData, setExportedData] = useState([]);
  const [switchCheck, setSwitchCheck] = useState({
    destinationType: true,
    createdBy: true,
    createdDate: true,
    modifiedBy: true,
    modifiedDate: true,
  });
  const [modal_scroll, setmodal_scroll] = useState(false);
  /**Filter column wise  */
  const [filterColumnWiseDestination, setFilterColumnWiseDestination] =
    useState([]);
  const [filterColumnWiseCity, setFilterColunmWiseCity] = useState([]);
  /**input change handller*/
  const onChangeHandller = (e) => {

    if (e.target.name === "is_active") {
      setModalValue({ ...modalValue, [e.target.name]: e.target.checked });
    } 
    else if (e.target.name === "city_name") {
      setModalValue({ ...modalValue, [e.target.name]: e.target.value });
    }
    else if (

      e.target.name === "destinationType" ||
      e.target.name === "createdBy" ||
      e.target.name === "createdDate" ||
      e.target.name === "modifiedBy" ||
      e.target.name === "modifiedDate"
    ) {
      setSwitchCheck({ ...switchCheck, [e.target.name]: !e.target.checked });
    }
      else 
      {
        setModalValue({ ...modalValue, [e.target.name]: e.target.value });
      }
    //else {
    //   const newArray = siteAndRateList.map((item, i) => {
    //     if (parseInt(e.target.id) === item.key) {
    //       return {
    //         ...item,
    //         [e.target.name]: e.target.value,
    //       };
    //     } else {
    //       return item;
    //     }
    //   });
    //   setSiteAndRateList(newArray);
    // }
  };
  /**This is for children data*/
  const expandedRowRender = (row) => {
    const columns = [
      {
        title: "Site",
        dataIndex: "site",
      },
      {
        title: "Private Rate",
        dataIndex: "private_rate",
        render: (text, record) => {
          return (
            <CurrencyShow
              currency={record.private_rate}
              currencySymbol={currencySymbol}
            />
          );
        },
      },
      {
        title: "SIC Rate",
        dataIndex: "sic_rate",
        render: (text, record) => {
          return (
            <CurrencyShow
              currency={record.sic_rate}
              currencySymbol={currencySymbol}
            />
          );
        },
      }
    ];
    return (
      <Table
        columns={columns}
        dataSource={row.siteSeeingList}
        pagination={false}
        className="m-3"
        scroll={{
          y: 300,
        }}
      />
    );
  };
  /**This is for over all search */
  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [showInvalid, setShowInvalid] = useState(false);
  /*Original Api Data**/
  const [dataSource, setDataSource] = useState({
    destinationList: [],
    cityList: [],
    slideList: [],
  });
  let { destinationList, cityList, slideList } = dataSource;
  /**This is state for select options*/
  const [selectOptions, setSelectOptions] = useState({
    destinationOptions: [],
    cityOptions: [],
  });

  /**This state for read Only and edit only*/
  const [readOnly, setReadOnly] = useState(false);
  let { destinationOptions, cityOptions } = selectOptions;

  /**City Site Name And Value Array*/
  const [siteAndRateList, setSiteAndRateList] = useState([
    {
      key: 1,
      site: null,
      private_rate: null,
      sic_rate: null,
      //Child_rate: null
    },
  ]);



  /**This function for delete city*/
  const removeSiteHandller = (id) => {
    if (siteAndRateList?.length > 1) {
      const filterSite = siteAndRateList.filter((item) => {
        return item.key !== id;
      });
      setSiteAndRateList(filterSite);
    } else {
      errornotify("Please add atleast one Site.");
    }
  };

  /**Over All table search in master */
  const searchInputHandller = (searchValue) => {
    setSearchText(searchValue);
    if (searchText !== "") {
      const filterData = slideList.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setFilterData(filterData);
      setExportableData(JSON.parse(JSON.stringify(filterData)));
    } else {
      setFilterData(slideList);
      setExportableData(JSON.parse(JSON.stringify(slideList)));
    }
  };

  /**This is for export table data to excel*/
  useEffect(() => {
    setExportableData(JSON.parse(JSON.stringify(slideList)));
  }, [
    slideList,
    switchCheck.destinationType,
    switchCheck.createdBy,
    switchCheck.createdDate,
    switchCheck.modifiedBy,
    switchCheck.modifiedDate,
  ]);
  useEffect(() => {
    if (searchText === "") {
      setExportableData(JSON.parse(JSON.stringify(slideList)));
    }
  }, [searchText]);
  /**Package Save*/
  const modalSaveHandller = async () => {
    try {
      setShowInvalid(true);
      // if (!city_name) {
      //   throw "City is required.";
      // }
      if (siteAndRateList.length > 0) {
        // const citySiteSeeingList = siteAndRateList.map((item, index) => {
        //   if (!item.site) {
        //     throw "Site is required.";
        //   }
        //   if (!item.rate) {
        //     throw "Rate is required.";
        //   }
        //   return {
        //     City_id: city?.value,
        //     Site: item.site,
        //     Rate: parseInt(item.rate),
        //     Is_active: is_active,
        //     Row_created_by: userName,
        //     Row_created_date: new Date(),
        //     Row_altered_by: userName,
        //     Row_altered_date: new Date(),
        //   };
        // });
        debugger;    
        
        /** Save Images list **/
        let imageList = [];
        /**new images add in list */
        if (selectedFiles?.length > 0 || getImageFileNames?.length > 0) {
          selectedFiles.map((item, index) => {
            imageList.push({
              City_site_seeing_image_id: 0,
              Image_name: item.name,
              Is_active: true,
              Is_primary:
                getImageFileNames?.length > 0 || index > 0 ? false : true,
              Row_created_date: new Date(),
              Row_created_by: userName,
              Row_altered_date: new Date(),
              Row_altered_by: userName,
            });
          });
          /**already exists images add in list */
          getImageFileNames?.map((itemAlreadyImage, index) => {
            imageList.push({
              City_site_seeing_image_id: itemAlreadyImage.city_site_seeing_image_id,
              Image_name: itemAlreadyImage.image_name,
              Is_active: true,
              Is_primary: itemAlreadyImage?.is_primary
                ? true
                : city_site_seeing_image_id === itemAlreadyImage.city_site_seeing_image_id
                ? is_primary
                : false,
              Row_created_date: new Date(),
              Row_created_by: userName,
              Row_altered_date: new Date(),
              Row_altered_by: userName,
            });
          });
        } else {
          throw "Images are required";
        }


        const updatableData = {
          Destination_type_id: modalValue?.destinationType,
          Destination_id: modalValue?.destination,
          // City_id: city?.value,
          City_id: modalValue.city_id,
          City_name: modalValue.city_name,
          //SiteSeeingList: siteAndRateList,
          City_site_seeing_id : modalValue.city_site_seeing_id,
          Site : modalValue.site ,
          Private_rate : modalValue.private_rate,
          Sic_rate : modalValue.sic_rate,
          Is_active: is_active,
          Row_created_by: userName,
          Row_created_date: new Date(),
          Row_altered_by: userName,
          Row_altered_date: new Date(),
          operation: modalValue?.operation,

          ImageList:
            readOnly &&
            getImageFileNames?.length > 0 &&
            selectedFiles?.length > 0
              ? null
              : imageList,
        };

        const response = await saveCitySiteSeeingList(updatableData);
        if (response?.status === "SUCCESS") {

          


        if (deleteImageList.length > 0) {
          /**This is for delete images */
          const response = await DeleteCitySiteSeeingImageList(deleteImageList);
          if (response.status === "ERROR") {
            throw response.message;
            // return;
          }
        }
        if (selectedFiles.length > 0) {
          /**This is for save images*/
          const formData = new FormData();
          formData.append("City_site_seeing_id", response.data[1]);
          selectedFiles.map(async (item, index) => {
            formData.append("file", item);
            let response = await SaveCitySiteSeeingImageList(formData);
            if (response.status === "ERROR") {
              throw response.message;
              // return;
            }
          });
        }
        
          await getAllList(1);
          
          successNotify(response?.message);
          tog_scroll();
          setShowInvalid(false);
         
          setModalValue({
            destinationType: null,
            destination: null,
            city_id: null,
            city_name: null,
            is_active: is_active,
          });
          setReadOnly(false);
        
        } else {
          errornotify(response?.message);
        }
      }
    } catch (error) {
      errornotify(error);
      // if (error === authError) {
      //   navigate("/login");
      // }
    }
  };

  /**This is for destination options*/
  if (destinationList?.length > 0 && destinationType?.value) {
    destinationOptions = [];
    const filterDestinationList = destinationList.filter((item) => {
      return (
        item.destination_type_id === destinationType?.value && item.is_active
      );
    });
    filterDestinationList.map((item, index) => {
      destinationOptions.push({
        label: item.destination_name,
        value: item.destination_id,
      });
    });
  }

  // /**This is option for city */
  // if (cityList?.length > 0 && destination?.value) {
  //   cityOptions = [];
  //   cityList
  //     .filter((item) => {
  //       return item.destination_id === destination?.value && item.is_active;
  //     })
  //     .map((item, index) => {
  //       cityOptions.push({
  //         label: item.city_name,
  //         value: item.city_id,
  //       });
  //     });
  // }

  /**This function for select option handller*/

  const slectOptionOnChange = (chooseOption, name, id) => {
    if (name === "destinationType") {
      setModalValue((prevValue) => {
        return {
          ...prevValue,
          destination: null,
          city_id: null,
          city_name: null
        };
      });
      setSiteAndRateList([
        {
          key: 1,
          site: null,
         // rate: null,
         private_rate: null,
         sic_rate: null,
        // child_rate: null,
        },
      ]);
    } else if (name === "destination") {
      setModalValue((prevValue) => {
        return {
          ...prevValue,
          city_id: null,
          city_name: null
        };
      });
      setSiteAndRateList([
        {
          key: 1,
          site: null,
          //rate: null,
          private_rate: null,
          sic_rate: null,
          //child_rate: null,
        },
      ]);
    }
    setModalValue((prevValue) => {
      return {
        ...prevValue,
        [name]: chooseOption,
      };
    });
  };

  /**This is for cancel handller */
  const cancelHandller = () => {
    tog_scroll();
    setReadOnly(false);
  };
  const getAllList = async (refreshLocalStrageList) => {
    try {
        
      

      const slideResult = await GetSlideList();

      debugger;

      if (slideResult?.data?.status === "SUCCESS") {
        setDataSource((prevDataSource) => {
          return {
            ...prevDataSource,
            slideList: slideResult.data.data,
          };
        });

        const uniqueDestination = filterUniqueDestination(
          slideResult.data.data
        );
        let filterDestination = [];
        uniqueDestination?.map((item, index) => {
          filterDestination.push({
            text: item?.destination_name,
            value: item?.destination_id,
          });
        });
        setFilterColumnWiseDestination(filterDestination);
        const uniqueCity = filterUniqueCity(slideResult.data.data);
        let filterCity = [];
        uniqueCity?.map((item, index) => {
          filterCity.push({
            text: item?.city_name,
            value: item?.city_id,
          });
        });
        setFilterColunmWiseCity(filterCity);
      } else {
        //throw citySiteSeeingResult?.message;
        errornotify(slideResult.data.message);
      }
    } catch (error) {
      errornotify(error);
    }
  };

  /**This useEffect for calling get api*/
  useEffect(() => {
    getAllList();
  }, []);

  /**This is Site And Rate list handller*/
  const handleAddCitySeeing = () => {
    setSiteAndRateList([
      ...siteAndRateList,
      { key: siteAndRateList?.length + 1,
        site: null, 
        //rate: null 
        private_rate: null,
        sic_rate: null,
       // child_rate: null,
      },
    ]);
  };


  /***Edit handller*/
  const editHandller = (record) => {
    console.log(record.is_active);
    setReadOnly(true);
   
    setModalValue({
      ...modalValue,
      city_site_seeing_id: record.city_site_seeing_id,
      destinationType: record.destination_type_id ,
      destination: record.destination_id,
      // city: {
      //   label: record.city_name,
      //   value: record.city_id,
      // },
      city_id: record.city_id,
      city_name: record.city_name,
      is_active: record.is_active,
      site: record.site,
      private_rate: record.private_rate,
      sic_rate: record.sic_rate,
      operation:"UPDATE"
    });

    //setSiteAndRateList([...record.siteSeeingList]);
    tog_scroll();
  };
  /** Columns of table*/
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      render: (text, record) => {
        return (

          <button
            type="button"
            className="btn btn-sm btn-info"
            onClick={() => editHandller(record)}
          >
            {" "}
            Edit{" "}
          </button>
        );
      },
    },   
    {
      title: "Slider Name",
      dataIndex: "slide_name",
      width: 100,
      defaultSortOrder: "ascend",
      filters: filterColumnWiseCity,
      filterSearch: true,
      //onFilter: (value, record) => record.city_id === value,
      sorter: {
        compare: (a, b) => a.slide_name.localeCompare(b.slide_name),
        multiple: 6,
      },
    },
    {
      title: "Slider Title",
      dataIndex: "slide_title",
      width: 100,
      defaultSortOrder: "ascend",
      filters: filterColumnWiseCity,
      filterSearch: true,
    //  onFilter: (value, record) => record.city_id === value,
      sorter: {
        compare: (a, b) => a.slide_title.localeCompare(b.slide_title),
        multiple: 6,
      },
    },   
    {
      title: "Slider Description",
      dataIndex: "slide_description",
      width: 140,
      defaultSortOrder: "ascend",
      filters: filterColumnWiseCity,
      filterSearch: true,
    //  onFilter: (value, record) => record.city_id === value,
      sorter: {
        compare: (a, b) => a.slide_title.localeCompare(b.slide_title),
        multiple: 6,
      },
    }, 
    {
      title: "Slider Image",
      dataIndex: "slide_image",
      width: 100,
      defaultSortOrder: "ascend",
      filters: filterColumnWiseCity,
      filterSearch: true,
      sorter: {
        compare: (a, b) => a.slide_title.localeCompare(b.slide_title),
        multiple: 6,
      },
      render: (text, record) => {
        // const onChangeHandller = async (e) => {
        //   e.preventDefault();
        //   setImageFormData([...imageFormData, e.target.files[0]]);
        //   record.destination_image = e.target.files[0].name;        
        // };
        return (
          <>           
            <Image
              src={`${url}/Images/Slide/${record.slide_image}`}
              //src={'https://aahvan.com/wp-content/uploads/2023/01/Dubai015.jpg'}
              alt="image"
              height={35}
              width={70}
              style={{ borderRadius: "3px" }}
            />     
          </>
        );
      },
    },   
    {
      title: "Is Active",
      dataIndex: "is_active",
      width: 130,
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      filterMode: "tree",
      onFilter: (value, record) => record.is_active === value,
      sorter: {
        compare: (a, b) => a.is_active - b.is_active,
        multiple: 5,
      },
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_active = e.target.checked)}
              defaultChecked={record.is_active}
              disabled
            />
          </div>
        );
      },
    },
    {
      title: "Created By",
      dataIndex: "row_created_by",
      width: 250,
      sorter: {
        compare: (a, b) => a.row_created_by.localeCompare(b.row_created_by),
        multiple: 4,
      },
      hidden: switchCheck.createdBy,
    },
    {
      title: "Created On",
      dataIndex: "row_created_date",
      width: 160,
      sorter: {
        compare: (a, b) => a.row_created_date.localeCompare(b.row_created_date),
        multiple: 3,
      },
      hidden: switchCheck.createdDate,
      render: (text, record) => {
        const date = new Date(record.row_created_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
    {
      title: "Modified By",
      width: 250,
      dataIndex: "row_altered_by",
      sorter: {
        compare: (a, b) => a.row_altered_by.localeCompare(b.row_altered_by),
        multiple: 2,
      },
      hidden: switchCheck.modifiedBy,
    },
    {
      title: "Modified On",
      width: 160,
      dataIndex: "row_altered_date",
      sorter: {
        compare: (a, b) => a.row_altered_date.localeCompare(b.row_altered_date),
        multiple: 1,
      },
      hidden: switchCheck.modifiedDate,
      render: (text, record) => {
        const date = new Date(record.row_altered_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);
  /**This state for a modal open and false */
  const [add_modal_is_open, set_add_modal_is_open] = useState(false);
  /**This function for a modal close and open */
  const tog_scroll = () => {
    set_add_modal_is_open(!add_modal_is_open);
  };
  /**This function for add button handller */
  const AddHandler = () => {

      /**This is option for city */
  // if (cityList?.length > 0 && destination?.value) {
  //   cityOptions = [];
  //   cityList
  //     .filter((item) => {
  //       return item.destination_id === destination?.value && item.is_active;
  //     })
  //     .map((item, index) => {
  //       cityOptions.push({
  //         label: item.city_name,
  //         value: item.city_id,
  //       });
  //     });
  // }
  setModalValue({ 
          ...modalValue, 
          // city_id: 0, 
          // city_name: "", 
          site : "",
          private_rate : "",
          sic_rate,
          operation:"ADD"});

  setSiteAndRateList([
    { key: 1,
      site: null, 
      private_rate: null,
      sic_rate: null,
    },
  ]);

  

    setReadOnly(false);
    tog_scroll();
  };

  /**set exported Data*/
  let exportArray = [];
  /** Export data*/
  useEffect(() => {
    setExportedData([]);
    if (exportTableData.length > 0) {
      exportTableData?.forEach((elementParent) => {
        elementParent?.slideList?.forEach((element) =>
          exportArray.push({
            destination_type:
              elementParent.destination_type_id === 1
                ? "Domestic"
                : "International",
            Destination: elementParent.destination_name,
            City: elementParent.city_name,
            Site: element.site,

            // Rate: element.rate,
            private_rate: element.private_rate,
            sic_rate: element.sic_rate,
           // child_rate: element.child_rate,
      
            ...(!switchCheck.createdBy && {
              RowCreatedBy: elementParent.row_created_by,
            }),
            ...(!switchCheck.createdDate && {
              RowCreatedDate: elementParent.row_created_date,
            }),
            ...(!switchCheck.modifiedBy && {
              RowModifiedBy: elementParent.row_altered_by,
            }),
            ...(!switchCheck.modifiedDate && {
              RowModifiedDate: elementParent.row_altered_date,
            }),
          })
        );
      });
      setExportedData(exportArray);
    }
  }, [exportTableData, filterData]);

  const ImportCitySiteSeeing = () => {
    navigate('../ImportCitySiteSeeing', {replace: true});
  };


  //File Upload Functions ----------------
  /**image select */
  function handleAcceptedFiles(files) {
    //setIsNullChild({ ...isNullChild, imagelistNullable: true });
    files.map((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        is_primary: false,
      });
    });
    setselectedFiles(files);
  }

   /**This is for remove selected image */
   const removeSelectedImages = (item) => {
    let newArray = selectedFiles.filter((itemImage) => {
      return itemImage.name !== item.name;
    });
    setselectedFiles(newArray);
  };


  const onDeleteHandller = (item) => {
    //setIsNullChild({ ...isNullChild, imagelistNullable: true });
    const removeSavedImages = getImageFileNames.filter((itemget, index) => {
      return itemget.city_site_seeing_image_id !== item.city_site_seeing_image_id;
    });
    setGetImageFileNames(removeSavedImages);
    setDeleteImageList([...deleteImageList, item]);
  };

    /**primary image radio box handller */
    const onImagePrimaryHandller = (e, item) => {
      setIsPrimaryImage({
        ...isPrimaryImage,
        is_primary: e.target.checked,
        city_site_seeing_image_id: item.city_site_seeing_image_id,
      });
    };

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  //--------------------------------------
  return (
    <>
      {" "}
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Slide"
            isSubTitle={true}
            pageTitle="Home"
          />

          
<div>    
    </div>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div>
                          <ButttonTravelNinjaz
                            backGroundColor="success"
                            className="add-btn me-1 my-1"
                            id="create-btn"
                            onCLickHancller={AddHandler}
                            buttonIcon={
                              <i className="ri-add-line align-bottom me-1"></i>
                            }
                            buttonText="Add"
                          />
                          {/**Export data */}
                          <Export
                            downloadExcel={downloadExcel}
                            exportedData={exportedData}
                            name="CitySiteSeeing"
                          />

                          <ButttonTravelNinjaz
                            backGroundColor="primary"
                            className="btn btn-secondary me-1 mx-1 my-1"
                            id="cancel-btn"
                            onCLickHancller={() =>
                              ImportCitySiteSeeing()
                            }                            
                            buttonText="Import"                            
                          />

                          {/**Hide and show column*/}
                          <ColumnVisibleAndHide
                            changeHandller={onChangeHandller}
                            switchCheck={switchCheck}
                          />
                        </div>
                      </Col>
                      {/**search filed in grid global */}
                      <Mastersearch inputHandller={searchInputHandller} />
                    </Row>
                  </div>
                  {/**Table information */}
                  <div>
                    <Table
                      dataSource={
                        searchText.length > 0 ? filterData : slideList
                      }
                      columns={columns}
                      scroll={{
                        y: 350,
                      }}
                      pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: [10, 20, 50, 100],
                        showTotal: (total, range) =>
                          `${range[0]}-${range[1]} of ${total} items  `,
                      }}
                      expandedRowRender={expandedRowRender}
                      bordered
                      locale={{
                        triggerDesc: null,
                        triggerAsc: null,
                        cancelSort: null,
                      }}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/**Modal */}

      <SlidePopupModal
          slideDetails = {slideDetails}
          isOpen={add_modal_is_open}
          tog_scroll = {tog_scroll}
          size="lg"  //lg
          scrollable={false}
          readOnly={readOnly}
          cancelHandller={cancelHandller}
      />

      {/* <SlidePopupModal
        tog_scroll={tog_scroll}
        headerName={`${!readOnly ? "Add" : "Edit"} - City & Site Seeing`}
        isOpen={add_modal_is_open}
        size="xl"  //lg
        scrollable={false}
        handleAddCitySeeing={handleAddCitySeeing}
        siteAndRateList={siteAndRateList}
        destinationOptions={destinationOptions}
        cityOptions={cityOptions}
        city_id={city_id}
        city_name={city_name}
        destination={destination}
        is_active={is_active}
        cancelHandller={cancelHandller}
        destinationType={destinationType}
        modalSaveHandller={modalSaveHandller}
        onChange={slectOptionOnChange}
        onChangeHandller={onChangeHandller}
        readOnly={readOnly}
        removeSiteHandller={removeSiteHandller}
        showInvalid={showInvalid}
        site={ site }
        private_rate={ private_rate }
        sic_rate={ sic_rate }

        selectedFiles = { selectedFiles }
        getImageFileNames={getImageFileNames}
        handleAcceptedFiles={handleAcceptedFiles}
        onDeleteHandller={onDeleteHandller}
        onImagePrimaryHandller={onImagePrimaryHandller}
        removeSelectedImages={removeSelectedImages}
      /> */}
    </>
  );
};

export default Slide;

import React, {useState} from "react";
import { Col, Input, Label, Row, Card, CardBody, CardHeader } from "reactstrap";
import Select from "react-select";
import ButttonTravelNinjaz from "../Common/GloablMaster/ButttonTravelNinjaz";
import B2BLabelInput from "../Common/B2BLabelInput";
import RequiredError from "../../common/RequiredError";
import DestinationTypeSelectList  from "./DestinationTypeSelectList";
import DestinationSelectList  from "./DestinationSelectList";
import { showDestinationType, showDestination } from "../../services/common/GlobalVariables";
import { url } from "../../services/common/const";

// Rating
import Rating from "react-rating";

// Import Scroll Bar - SimpleBar
import SimpleBar from 'simplebar-react';
import QuillTextEditor  from "../../components/package/QuillTextEditor";

import {getPackageItineraryFile } from "../../services/Package";

const DestinationAndPackage = ({
  destinationType,
  destination,
  packageUrl,
  packageMetaTitle,
  packageMetaDescription,
  package_id,
  packageName,
  packageDescription,
  packagePrice,
  packagePriceBeforeDiscount,
  packageRating,
  packageRatingAverage,
  packageCommision,   
  showInvalid, 
  onChangeHandller,  
  onChangeQuillEditorHandller,
  onChangeRatingHandller,
  cancelHandller, 
  specialCheckBox,  
  is_active,
  packageOrderNo,
  //modalSaveDestinationAndPackageHandller,
  modalSaveHandller,
  onPackageItineraryFileChange,
  selectedPackageItineraryFile,
  packageItineraryFile,
  removePackageItineraryFileHandler,
 // setSelectedPackageItineraryFile,
}) => {

// const [destinationTypeOptions1, setDestinationTypeOptions] = React.useState([]);
// const [destinationOptions1, setDestinationOptions] = React.useState([]);

const [packageUrlChange, setPackageUrlChange] = React.useState(packageUrl);

const [packageItineraryFileChange, setPackageItineraryFileChange] = React.useState(packageItineraryFile);

//alert('package_:' + JSON.stringify(package_));

const onChangeAutoFillPackageUrl = (e) => {  

  let tempPackageUrl = e.target.value?.toLowerCase();
  tempPackageUrl = tempPackageUrl.replaceAll(' ', '-');

  setPackageUrlChange(tempPackageUrl);
};


  return (
    <> 
     {/* style={{height: "50vh"}} */}

      {/* <div className="cardItems"> */}

      <div>
        <Row>

        <Col lg={12}>
          <Card>
            <CardHeader className="align-items-center d-flex bg-light">
              <h4 className="card-title mb-0">Package Details</h4>
            </CardHeader>
            <CardBody>

            <SimpleBar style={{ maxHeight: "60vh" }}  className="px-3">
               
           

            {/* <div  className="cardItems" style={{height: "40vh"}} > */}

              <Row>         

               <div className="card">
                    <div className="card-header py-2">
                        <h5 className="card-title mb-0">Destination Details</h5>
                    </div>
        
                    <CardBody>
                                                    
                    <Row >                            

                      {(showDestinationType == "true" || showDestinationType == true )? (
                          <Col xxl={6} className="mb-3">
                          <div>
                            <label htmlFor="destinationType" className="form-label">
                              Destination Type
                            </label>

                            <DestinationTypeSelectList destinationTypeSelected = {destinationType} />
                          
                            {destinationType == null && showInvalid ? (
                              <RequiredError errorMessage={" Destination Type is Required"} />
                            ) : (
                              ""
                            )}
                    
                          </div>
                        </Col>
                        ) :  <>  </>
                        }

                      {(showDestination == "true" || showDestination == true )? (
                          <Col lg={6} className="mb-3">
                          <div>
                            <label htmlFor="destination" className="form-label">
                              Destination
                            </label>                      

                              <DestinationSelectList destinationSelected = {destination} />

                              {destination == null && showInvalid ? (
                                <RequiredError errorMessage={" Destination is Required"} />
                              ) : (
                                ""
                              )}                

                          </div>                     
                        </Col>
                      ) :  <>  </>
                      }
                      </Row>      
                    </CardBody>
                 </div>

                  <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Package Primary Details</h5>
                                </div>
                                <CardBody>

                                  <Row>
                                      <Col xxl={6} className="mb-3">
                                        {/**Package name */}
                                        <div>
                                          {/* <label htmlFor="packageName" className="form-label">
                                            Package Name
                                          </label>
                                          <Input
                                            type="text"
                                            className="form-control"
                                            name="packageName"
                                            value={packageName}
                                            defaultValue={packageName}
                                            onChange={(e) => onChangeHandller(e)}
                                            invalid={packageName?.length < 1 && showInvalid}
                                            required
                                          /> */}
                                          <B2BLabelInput
                                            classNameInput={"form-control"}
                                            classNameLabel={"form-label"}
                                            id={"packageName"}                        
                                            labelName={"Package Name"}
                                            value={packageName}
                                            defaultValue={packageName}
                                            invalid={packageName?.length < 1 && showInvalid}
                                            name="packageName"
                                            //onChangeHandller={onChangeHandller}
                                            onChangeHandller={e => { onChangeHandller(e); onChangeAutoFillPackageUrl(e) }}
                                            
                                            //onChangeHandller={onChangeHandller('packageName')}                          
                                            type="text"
                                          />
                                          {packageName?.length < 1 && showInvalid ? (
                                            <RequiredError errorMessage={" Package Name is Required"} />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </Col>

                                      <Col xxl={6} className="mb-3">
                                        {/**Package URL */}
                                        <div>                        
                                          <B2BLabelInput
                                            id="packageUrl"
                                            name="packageUrl"
                                            type="text"
                                            classNameInput={"form-control"}
                                            classNameLabel={"form-label"}                        
                                            labelName={"Package URL (It will be used as a route in Website)"}
                                            value={packageUrlChange}
                                            defaultValue={packageUrlChange }
                                            invalid={packageUrlChange?.length < 1 && showInvalid}
                                        
                                            onChangeHandller={e => {onChangeHandller(e); onChangeAutoFillPackageUrl(e) }}                                      
                                          />
                                        
                                          {packageUrlChange?.length < 1 && showInvalid ? (
                                            <RequiredError errorMessage={" Package Title is Required"} />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </Col>

                                      <Col xxl={12}>                  
                                        <div>
                                          <Label htmlFor="packageDescription" className="form-label">
                                            Description
                                          </Label>
                                            <QuillTextEditor                      
                                                onChangeQuillEditorHandller={onChangeQuillEditorHandller}    
                                                editorId = {"packageDescription"}                            
                                                editorName = {"packageDescription"}
                                                editorHTML={packageDescription}
                                            />               
                                        </div>
                                        </Col>   
                                    
                                  </Row>

                                  <Row>
    
                                  <Col xxl={6} className="mb-3">
                                        {/**Package Meta Title */}
                                        <div>
                                        
                                          <B2BLabelInput
                                            classNameInput={"form-control"}
                                            classNameLabel={"form-label"}
                                            id={"packageMetaTitle"}                        
                                            labelName={"Package Meta Title"}
                                            value={packageMetaTitle}
                                            defaultValue={packageMetaTitle}
                                            invalid={packageMetaTitle?.length < 1 && showInvalid}
                                            name="packageMetaTitle"
                                            onChangeHandller={e => { onChangeHandller(e)}}                     
                                            type="text"
                                          />
                                          {packageMetaTitle?.length < 1 && showInvalid ? (
                                            <RequiredError errorMessage={" Package Meta Title is Required"} />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </Col>

                                      <Col xxl={6} className="mb-3">
                                        {/**Package Meta Description */}
                                        <div>
                                        
                                          <B2BLabelInput
                                            classNameInput={"form-control"}
                                            classNameLabel={"form-label"}
                                            id={"packageMetaDescription"}                        
                                            labelName={"Package Meta Description"}
                                            value={packageMetaDescription}
                                            defaultValue={packageMetaDescription}
                                            invalid={packageMetaDescription?.length < 1 && showInvalid}
                                            name="packageMetaDescription"
                                            onChangeHandller={e => { onChangeHandller(e)}}                     
                                            type="text"
                                          />
                                          {packageMetaDescription?.length < 1 && showInvalid ? (
                                            <RequiredError errorMessage={" Package Meta Desc is Required"} />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </Col>
                                  </Row>
                                  
                                  <Row>
                                    <Col className="mb-2 my-1" xxl={2}>
                                      <div>
                                          <label htmlFor="packageOrderNo" className="form-label">
                                            Package Order No
                                          </label>
                                          <div className="input-group">

                                            <Input
                                              type="number"
                                              className="form-control"
                                              name="packageOrderNo"
                                              value={packageOrderNo}
                                              defaultValue={packageOrderNo}
                                              onChange={(e) => onChangeHandller(e)}
                                              // invalid={
                                              //   (packageOrderNo?.length < 1 || !packageOrderNo) &&
                                              //   showInvalid
                                              // }
                                              //required
                                            />                       
                                          </div>
                                          {/* {(packageOrderNo?.length < 1 || !packageOrderNo) &&
                                          showInvalid ? (
                                            <p style={{ fontSize: "12px", color: "red" }}>
                                              Package Order No is Required
                                            </p>
                                          ) : (
                                            ""
                                          )}                */}
                                      </div>
                                      </Col>
                      
                                  </Row>
                                </CardBody>
                  </div>


                  <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Package Pricing Details</h5>
                                </div>
                                <CardBody>
                                    <Row>
                                    <Col xxl={3} className="mb-3" >
                                        <div>
                                          <label htmlFor="packagePrice" className="form-label">
                                            Package Price
                                          </label>
                                          <div className="input-group">
                                            <Input
                                              type="number"
                                              className="form-control"
                                              name="packagePrice"
                                              value={packagePrice}
                                              defaultValue={packagePrice}
                                              onChange={(e) => onChangeHandller(e)}
                                              invalid={
                                                (packagePrice?.length < 1 || !packagePrice) &&
                                                showInvalid
                                              }                
                                              required
                                            />
                                            <span className="input-group-text" id="basic-addon1">
                                                ₹
                                            </span>
                                          </div>

                                          {/* {"packagePrice -" + packagePrice} */}

                                          {(packagePrice?.length < 1 || !packagePrice) &&
                                          showInvalid ? (
                                            <p style={{ fontSize: "12px", color: "red" }}>
                                              Package Price is Required
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </Col>
                                      <Col xxl={3} className="mb-3" >
                                        <div>
                                          <label htmlFor="packagePriceBeforeDiscount" className="form-label">
                                            Before Discount Price
                                          </label>
                                          <div className="input-group">
                                            <Input
                                              type="number"
                                              id="packagePriceBeforeDiscount"
                                              name="packagePriceBeforeDiscount"
                                              className="form-control"                          
                                              value={packagePriceBeforeDiscount}
                                              defaultValue={packagePriceBeforeDiscount}
                                              onChange={(e) => onChangeHandller(e)}
                                              invalid={
                                                (packagePriceBeforeDiscount?.length < 1 || !packagePriceBeforeDiscount) &&
                                                showInvalid
                                              }                
                                              required
                                            />
                                            <span className="input-group-text" id="basic-addon1">
                                                ₹
                                            </span>
                                          </div>

                                          {/* {"packagePriceBeforeDiscount -" + packagePriceBeforeDiscount} */}

                                          {(packagePriceBeforeDiscount?.length < 1 || !packagePriceBeforeDiscount) &&
                                          showInvalid ? (
                                            <p style={{ fontSize: "12px", color: "red" }}>
                                              Before Discount Price is Required
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </Col>
                                    
                                      <Col xxl={2} className="mb-3" >
                                        <div>
                                          <label htmlFor="packageCommision" className="form-label">
                                            Package Review Rating
                                          </label>

                                          <div className="input-group">

                                            <Input
                                              type="decimal"
                                              className="form-control"
                                              name="packageRating"
                                              value={packageRating}
                                              defaultValue={packageRating}
                                              onChange={(e) => onChangeHandller(e)}
                                              invalid={
                                                (packageRating?.length < 1 || !packageRating) &&
                                                showInvalid
                                              }
                                              required
                                            />                       
                                          </div>
                                          {(packageRating?.length < 1 || !packageRating) &&
                                          showInvalid ? (
                                            <p style={{ fontSize: "12px", color: "red" }}>
                                              Package Rating is Required
                                            </p>
                                          ) : (
                                            ""
                                          )}               
                                        </div>

                                          {/* <div className="input-group">
                                              <Rating
                                                id="packageRating"
                                                name="packageRating"
                                                style={{ fontSize: "22px" }}
                                                precision={0.5}
                                                stop={5}
                                                emptySymbol="mdi mdi-star-outline text-muted "
                                                fullSymbol="mdi mdi-star text-warning "
                                                initialRating={packageRating || 0}
                                                //onChange={(e) => onChangeHandller(e)}
                                                onChange={onChangeRatingHandller}                                                        
                                              />                         
                                                        
                                        </div> */}
                                      </Col>

                                      <Col xxl={2} className="mb-3" >
                                        <div>
                                          <label htmlFor="packageRatingAverage" className="form-label">
                                            Package Review Count
                                          </label>
                                          <div className="input-group">

                                            <Input
                                              type="number"
                                              className="form-control"
                                              name="packageRatingAverage"
                                              value={packageRatingAverage}
                                              defaultValue={packageRatingAverage}
                                              onChange={(e) => onChangeHandller(e)}
                                              invalid={
                                                (packageRatingAverage?.length < 1 || !packageRatingAverage) &&
                                                showInvalid
                                              }
                                              required
                                            />                       
                                          </div>
                                          {(packageRatingAverage?.length < 1 || !packageRatingAverage) &&
                                          showInvalid ? (
                                            <p style={{ fontSize: "12px", color: "red" }}>
                                              Package Rating Average is Required
                                            </p>
                                          ) : (
                                            ""
                                          )}               
                                        </div>
                                      </Col>

                                      <Col xxl={2} className="mb-3" >
                                        <div>
                                          <label htmlFor="packageCommision" className="form-label">
                                            Package Commision
                                          </label>
                                          <div className="input-group">
                                            <Input
                                              type="number"
                                              className="form-control"
                                              name="packageCommision"
                                              value={packageCommision}
                                              defaultValue={packageCommision}
                                              onChange={(e) => onChangeHandller(e)}
                                              invalid={
                                                (packageCommision?.length < 1 || !packageCommision) &&
                                                showInvalid
                                              }
                                              // readOnly={readOnly}
                                              required
                                            />
                                            <span className="input-group-text" id="basic-addon1">
                                              %
                                            </span>
                                          </div>
                                          {(packageCommision?.length < 1 || !packageCommision) &&
                                          showInvalid ? (
                                            <p style={{ fontSize: "12px", color: "red" }}>
                                              Package Commision is Required
                                            </p>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </Col>
                                    </Row>
                                </CardBody>
                  </div>                  
                   
                   <div className="card">
                        <div className="card-header">
                            <h5 className="card-title mb-0">Package Categories</h5>
                        </div>
                        <CardBody>
                            <Row>
                           
                    <Col className="mb-2 my-1" xxl={12}>
                      <div className="d-xl-flex">
                        <div className="form-check form-radio-success m-2">
                          <B2BLabelInput
                            classNameInput="form-check-input"
                            classNameLabel="form-check-label"
                            defaultChecked={specialCheckBox.isbestselling}
                            id="isbestselling"
                            labelName="Is Best Selling (Featured Trip)"
                            name="isbestselling"
                            type="checkbox"
                            onChangeHandller={onChangeHandller}
                          />
                        </div>
                        <div className="form-check form-radio-success m-2">
                          <B2BLabelInput
                            classNameInput="form-check-input"
                            classNameLabel="form-check-label"
                            defaultChecked={specialCheckBox.ishoneymoonpackage}
                            id="ishoneymoonpackage"
                            // labelName="Is Honeymoon Package"
                            labelName="Is Customize Tour"
                            name="ishoneymoonpackage"
                            type="checkbox"
                            onChangeHandller={onChangeHandller}
                          />
                        </div>
                        <div className="form-check form-radio-success m-2">
                          <B2BLabelInput
                            classNameInput="form-check-input"
                            classNameLabel="form-check-label"
                            defaultChecked={specialCheckBox.isfamilypackage}
                            id="isfamilypackage"
                            //labelName="Is Family Package"
                            labelName="Is Group Tour"
                            name="isfamilypackage"
                            type="checkbox"
                            onChangeHandller={onChangeHandller}
                          />
                        </div>
                        <div className="form-check form-radio-success m-2">
                          <B2BLabelInput
                            classNameInput="form-check-input"
                            classNameLabel="form-check-label"
                            defaultChecked={specialCheckBox.isUpcomingPackage}
                            id="isUpcomingPackage"
                            labelName="Is Upcoming Tour"
                            name="isUpcomingPackage"
                            type="checkbox"
                            onChangeHandller={onChangeHandller}
                          />
                        </div>
                      </div>
                                    
                    </Col>

                            </Row>
                        </CardBody>
                   </div>                
                           
             

             <div className="card">
                  <div className="card-header">
                      <h5 className="card-title mb-0">General Details</h5>
                  </div>
                  <CardBody>
                        <Row>
                            <Col className="mb-2 my-1" xxl={2}>
                              <div className="form-check form-switch form-switch-success my-3">
                                  <B2BLabelInput
                                    classNameInput="form-check-input"
                                    classNameLabel="form-check-label"
                                    defaultChecked={is_active}
                                    id="is_active"
                                    labelName="Is Active"
                                    name="is_active"
                                    type="checkbox"
                                    role={"switch"}
                                    onChangeHandller={onChangeHandller}
                                  />
                              </div>     
                           </Col>        
                        </Row>
                        <Row>
                    <Col className="mb-2 my-1" xxl={1}>
                       <label> Itinerary PDF </label>
                    </Col>

                    <Col className="mb-2 my-1" xxl={9}>
                        <Input type="file" className="form-control" aria-label="file example"
                         onChange={onPackageItineraryFileChange}
                          />         
                    </Col>                   
             </Row>       
             <Row>
                    <Col className="mb-2 my-1" xxl={1}>                     
                    </Col>

                    <Col className="mb-2 my-1 fs-12" xxl={4}>                        
                      <a
                        // href={`${url}/ItineraryFile/Package/${package_id}/${selectedPackageItineraryFile}`}
                        href={`${url}/api/Package/getPackageItineraryFile?package_id=${package_id}&package_itinerary_file=${packageItineraryFileChange}`}
                        download="Package_template"
                        target="_blank"
                        rel="noreferrer"

                      >
                      {packageItineraryFileChange}
                      </a>                                     
                    </Col>

                    <Col className="" xxl={2}>

                    {packageItineraryFileChange != null && packageItineraryFileChange != "" ? (
                           <i
                           className="ri-close-line"
                           onClick={() => { removePackageItineraryFileHandler(); setPackageItineraryFileChange("") }}
                           style={{
                             fontSize: "25px",
                             cursor: "pointer",
                           }}
                           title="Remove Package Itinerary File"
                         >                        
                         </i>      
                         
                          ) : (
                            ""
                          )}                       
                    </Col>
             </Row>   
                  </CardBody>
              </div>
              </Row>
            
           
            </SimpleBar>
          </CardBody>
          </Card>
        </Col>
        </Row>
      </div>
      <div className="d-flex align-items-center gap-3 mt-4">
        <div className="right ms-auto nexttab nexttab">
         

          {/* <ButttonTravelNinjaz
            backGroundColor={"primary"}
            onCLickHancller={modalSaveDestinationAndPackageHandller}
            buttonText={"Save"}
            className="mx-1"
            type={"button"}
          /> */}


          <ButttonTravelNinjaz
            backGroundColor={"primary"}
            onCLickHancller={modalSaveHandller}
            buttonText={"Save"}
            className="mx-1"
            type={"button"}
          />

          <ButttonTravelNinjaz
            backGroundColor={"danger"}
            onCLickHancller={cancelHandller}
            buttonText={"Cancel"}
            className="mx-1"
          />
        </div>
      </div>
    </>
  );
};

export default DestinationAndPackage;

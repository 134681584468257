/**base url*/

//Server 
//export const url = "https://api.aahvan.com"; //Web API for ADMIN

//Test
//export const url = "https://api.travelninjaz.com"; //Web API for ADMIN


// LOCAL
//export const url = "https://localhost:44395"; //Web API for ADMIN

export const url = process.env.REACT_APP_API_URL;

export const googleClientId = "649788567312-0geettqbnapr28vgathmpposdv3o5jf7.apps.googleusercontent.com";
export const faceBookAppId = "6482949298392048";
export const authError = "Authorization error";

export const applicationType = "ADMIN";

